<template>
<div class="collectBox">
  <!-- <Header></Header> -->
  <div class="collectList">
    <div class="infinite-list-wrapper" :style="collectList.length == 0 ? 'height: 72vh;':''">
      <van-empty :image="require('@/assets/null.svg')" v-if="collectList.length == 0"/>
      <van-list
      v-else
      class="list"
      v-model="lazyLoading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
      >
        <!-- <ul class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
          <div v-for="(item,index) in collectList" :key="index" @click="goPage(item.site_url)" class="list-item">
            <van-swipe-cell>
              <div class="imgbox">
                <img :src="item.page_style_url">
              </div>
              <div class="center">
                 <p>{{item.title}}</p>
                 <span>{{item.collection_time}}</span>
                 <div class="preview-count">
                   <span class="go_img">{{item.view_count}} &nbsp;浏览 </span>
                   <img src="@/assets/RiCheng/right_arrow.svg" alt="">
                </div>
              </div>
              <!-- <span class="right">查看详情 <i class="el-icon-arrow-right"></i></span> -->
              <template #right> 
                <van-button  @click.stop="delData(item)" square type="danger" text="删除" class="delete-button" /> 
              </template> 
            </van-swipe-cell>
          </div>
        <!-- </ul> -->
        </van-list>
        <!-- <p class="p_height" v-if="loading" style="font-size: 12px; width:100%; text-align: center;">加载中...</p>
        <p class="p_height" v-if="noMore" style="font-size: 12px; width:100%; text-align: center;">
          <img v-if="collectList.length == 0" src="../../assets/null.svg" alt="">
          <span v-else>没有更多了</span>
        </p> -->
    </div>
    <Support></Support>
    <sideBar></sideBar>
  </div>
</div>
</template>
<script>
import Header from '@/components/header';
import Support from '@/components/support';
import sideBar from '@/components/sidebar';
export default {
  components:{
      Header,
      sideBar,
      Support,
  },
  data(){
    return{
      loading: false,
      collectList:[],
      collectListData: {
        page: 1,
        page_num: 15
      },
      total: 0,
      count:0,
      noMore: false,
      lazyLoading:false, //懒加载
      finished:false,
    }
  },
  computed: {
    // noMore () {
    //   return this.collectList.length >= this.total
    // },
    // disabled () {
    //   return this.loading || this.noMore
    // }
  },
  created(){
      // this.getCollectList()
  },
  mounted(){
    this.getCollectList()
    // document.querySelector('.suspend-btn .home-box').style.display = 'none'
  },
  methods: {
    goPage(url){
      location.href = url
    },
    async getCollectList () {
      const res = await this.$store.dispatch('list_collection_record', this.collectListData)
      if(res.data.code == 200){
        if(Array.isArray(res.data.data)){
          // this.collectList = [ ...this.collectList, ...res.data.data]
          this.historyList = []
        }else{
          if(this.collectList.length == 0){
            this.collectList =res.data.data.data
          }else{
            this.collectList = [...this.collectList, ...res.data.data.data]
          }
          this.total = res.data.data.total
          this.count += this.collectListData.page_num
          this.lazyLoading = false
        }
      }
    },
    // load () {
    //   this.loading = true
    //   setTimeout(() => {
    //     this.collectListData.page++
    //     this.getCollectList()
    //   }, 0)
    // },
    // load () {
    //   if(this.collectList.length > this.total - 1){
    //     this.loading = true
    //     setTimeout(() => {
    //       this.collectListData.page++
    //       this.getCollectList()
    //     }, 0)
    //   }else{
    //     this.noMore = true
    //   }
    // },
    onLoad() {
      if(this.total > this.count){
          this.lazyLoading = true
          setTimeout(() => {
            this.collectListData.page+=1
            this.getCollectList()
          }, 1000);
      }else{
          this.finished = true;
      }
    },
    async delData (val) {
      const res = await this.$store.dispatch('unfavorite_the_site', {
        site_id: val.site_id,
        site_url: val.site_url.split('event/')[1]
      })
      if(res.data.code == 200){
        if(val.site_url.split('event/')[1] == sessionStorage.getItem('siteUrl')){
          if(localStorage.getItem('station_collect')){
            localStorage.removeItem('station_collect')
            localStorage.setItem('station_collect', 0)
          }
        }
        let arr = []
        this.collectList.forEach(item => {
          if(item.site_id != val.site_id){
              arr.push(item)
          }
        })
        this.collectList = arr
      }else{
        this.$toast(res.data.message)
      }
    }
  }
}
</script>
<style lang='less' scoped>
  .goods-card{
    margin: 0;
  }
  /deep/.van-empty,/deep/.van-loading{
        position: absolute;
        top: 30%;
        left:50%;
        transform:translateX(-50%);
    }
    /deep/.van-empty{
        width: 100%;
        .van-empty__image{
            width: 300px;
            height: 300px;
        }
    }
    /deep/.van-list__finished-text{
        margin-top: 10px;
    }
    /deep/.van-list__loading{
        .van-loading{
            position: relative!important;
        }
    }
  /deep/ .van-swipe-cell__right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.68rem;
    padding-left: .08rem;
    .delete-button {
      height: 0.38rem;
      width: 0.6rem;
      border-radius: .03rem;
      font-size: .12rem;
    }
    /deep/ .van-button--normal{
      padding: 0;
    }
  }
  .collectBox{
    width: 100%;
    height:calc(100% - 0.44rem);
    background: #F7F8FA;
    position: relative;
    &::-webkit-scrollbar{
      display: none;
    }
    .collectList{
      width:100%;
      height: 100%;
      background: #ffffff;
      overflow: hidden;
      overflow-y: scroll;
      // position: absolute;
      // top: 0;
      position: relative;
      .infinite-list-wrapper{
        min-height: calc(100% - 36px);
        .p_height{
          // height: 0.7rem;
          line-height: 0.7rem;
          font-size: 0.12rem;
          img{
            position: fixed;
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
      /deep/ .van-swipe-cell{
        width: 100%;
        .van-swipe-cell__wrapper{
          display: flex;
          justify-content: space-between;
        }
      }
      .data_null{
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .list{
        width:94%;
        margin:0 auto;
        border-bottom:1px solid #E5E5E5;
        .list-item{
          display: flex;
          justify-content: space-between;
          padding: 12px 0px 12px 0px;
          .imgbox{
            width: 1.1rem;
            height: .61rem;
            margin: 0 10px 0 6px;
            box-sizing: border-box;
            img{
              width: 1.1rem;
              height: .61rem;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }
          }
          .center{
            width:65%;
            // flex: 1;
            height: 61px;
            // padding: 6px 0;
            p{
              font-size: 14px;
              line-height: 20px;
              color: #000;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 4px;
            }
            span{
              font-size: 12px;
              line-height: 20px;
              color: #8B8B8D;
              display: block;
              overflow: hidden;
            }
            .preview-count{
              font-size: 12px;
              line-height: 20px;
              color: #8B8B8D;
              display: block;
              overflow: hidden;
              display: flex;
              justify-content: space-between;
              img{
                display: block;
                width: 22px;
                margin-right: 14px;
              }
            }
          }
          .right{
            width: 22%;
            font-size: 12px;
            height: 60px;
            line-height: 60px;
            color: #575F64;
          }
        }
      }
    }
    .tips{
      width: 100%;
      height: .6rem;
      font-size: .14rem;
      display: block;
      text-align: center;
      line-height: .6rem;
      color: #999;
    }
  }
</style>